<template>
  <v-dialog v-model="dialog" width="650px">
    <template v-slot:activator="{ on, attrs }">
      <a class="white--text pa-1 pa-md-0" v-bind="attrs" v-on="on"
        >Terms &amp; Conditions</a
      >
    </template>

    <v-card>
      <v-card-title class="text-h5">
        Terms &amp; Conditions
      </v-card-title>

      <v-card-text>
        <h4 style="text-align: left; font-size: 8pt;">
          Términos y condiciones HUBITER
        </h4>
        <br />
        <p style="text-align: justify; font-size: 8pt;">
          Los presentes Términos y Condiciones de uso de la plataforma (en lo
          sucesivo “Términos y Condiciones”) que rigen la navegación y el uso
          del Sitio Web www.hubiter.biz o alguno de sus subdominios (En lo
          sucesivo “Plataforma”) propiedad de HUBITER, S.A.P.I. de C.V. (En lo
          sucesivo “HUBITER”).
        </p>
        <h4 style="text-align: justify; font-size: 8pt;">
          DEFINICIONES
        </h4>
        <br />
        <p style="text-align: justify; font-size: 8pt;">
          Para los fines de los presentes Términos y Condiciones, los siguientes
          términos lingüísticos, tendrán el significado que a continuación se
          les atribuye, en el entendido que dicho significado será igualmente
          aplicable al singular y al plural de las definiciones:
        </p>
        <div style="text-align: justify; font-size: 8pt;">
          <ul style="font-size: 8pt;">
            <li>
              Activo Virtual: Representación de valor registrada
              electrónicamente y utilizada entre el público como medio de pago
              para todo tipo de actos jurídicos y cuya transferencia únicamente
              puede llevarse a cabo a través de medios electrónicos.
            </li>
            <li>
              Cliente: El usuario quien hace uso de los Servicios en la
              Plataforma HUBITER.
            </li>
            <li>
              Contraprestación: Las tarifas a las que estará sujetos los
              Servicios.
            </li>
            <li>
              Contrato: Documento elaborado unilateralmente por HUBITER para
              establecer en formatos uniformes los términos y condiciones
              aplicables a la celebración de las operaciones que lleve a cabo
              con los Clientes.
            </li>
            <li>
              Cuenta: Hace referencia al usuario y contraseña que el Cliente
              genera en la Plataforma HUBITER a efecto de que este último tenga
              acceso la misma y pueda hacer uso de los Servicios.
            </li>
            <li>
              Plataforma: Medio de acceso a los Servicios, tanto en plataforma
              móvil como en la plataforma web www.hubiter.biz, propiedad de
              HUBITER.
            </li>
            <li>
              Servicios: Los servicios que presta HUBITER consisten en facilitar
              el intercambio de Activos Virtuales mediante la realización de
              operaciones de compra o venta de dichos Activos Virtuales
              propiedad del Cliente a través de la Plataforma HUBITER.
            </li>
            Uso y restricciones de la plataforma
          </ul>
        </div>
        <br />
        <p style="text-align: justify; font-size: 8pt;">
          Los presentes Términos y Condiciones constituyen un acuerdo vinculante
          entre cualquier Cliente y HUBITER para el uso de la Plataforma.
          Previamente a la celebración de los presentes Términos y Condiciones,
          HUBITER le ha informado al Cliente sobre el contenido del mismo,
          informándole además que mantendrá a su disposición, en la página web
          de HUBITER y en sus oficinas, versiones actualizadas y vigentes de los
          términos y condiciones aplicables, así como de los preceptos legales
          aplicables los cuales se Anexan a los presentes Términos y
          Condiciones.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Si el Cliente no acepta en forma absoluta y completa los Términos y
          Condiciones deberá abstenerse de acceder, navegar y/o hacer uso de la
          Plataforma, en caso contrario se entenderá que da por aceptados
          voluntariamente, sin ningún vicio en su consentimiento, cada una de
          las condiciones contempladas en los presentes Términos y Condiciones.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El Cliente en este acto manifiesta que en caso de ser persona física
          actúa por cuenta propia. En caso de ser persona física y actuar en
          nombre y representación de un tercero HUBITER se reserva el derecho de
          excluir dicha persona física de la Plataforma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El Cliente garantiza poseer la edad mínima necesaria de 18 (dieciocho)
          años para utilizar y solicitar cualquier Servicio ofrecido a través de
          la Plataforma. En caso de no cumplir con la edad mínima, en ninguna
          circunstancia se tendrá autorización para utilizar la Plataforma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El Cliente deberá de abstenerse de utilizar la Plataforma para fines
          distintos establecidos en la misma, ni realizar cualquier actividad
          ilegal, ilícita, fraudulenta o de uso no autorizado.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER se reserva el derecho de excluir al Cliente de la Plataforma,
          cuando éste no haga un uso adecuado de la misma o de cualquier forma
          incumpla con los presentes Términos y Condiciones.
        </p>
        <h4 style="text-align: justify; font-size: 8pt;">
          Operaciones y servicios que ofrece
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER por medio de la Plataforma, pondrá a disposición de los
          Clientes los Servicios. La Plataforma será en todo momento operada y
          administrada por HUBITER. El Cliente reconoce que al solicitar
          cualquier Servicio dentro de la Plataforma conoce y acepta los
          términos y características establecidos para gestionar procesos de
          Compensación, Liquidación, Conciliación Monetaria, Registro Contable,
          Prevención y Monitoreo, entre otros.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER no realiza operaciones traslativas de dominio, sólo pone a
          disposición del Cliente la Plataforma como un medio para gestionar
          transacciones electrónicas del Cliente y/o servicios de BackOffice.
          Las características de las Operaciones y Servicios estarán detalladas
          en el Contrato respectivo que celebre el Cliente.
        </p>
        <h4 style="text-align: justify; font-size: 8pt;">
          Disponibilidad de la plataforma
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER mantendrá disponible la Plataforma las 24 horas los 365 días
          del año; sin embargo, los horarios y niveles de servicio estarán
          detallados en el Contrato respectivo que celebre el Cliente. En caso
          de existir alguna falla dentro de la Plataforma que no permita
          mantener la disponibilidad indicada en él Contrato, HUBITER notificará
          vía correo electrónico al Cliente las opciones para continuar con la
          disponibilidad de los Servicios.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER será responsable de la continuidad de la prestación de los
          Servicios, la calidad y contenidos de los mismos garantizando la
          continuidad de la operación conforme al plan de continuidad que
          HUBITER mantenga para tal efecto.
        </p>
        <h4 style="text-align: justify; font-size: 8pt;">
          Mecanismos y procedimientos de identificación y autenticación de
          clientes.
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          Para poder hacer uso de la Plataforma, el Cliente tendrá que
          solicitarlo vía Mesa de Ayuda y se creará una Cuenta en la Plataforma.
          Para efectos de lo anterior, se le solicita enviar formato de accesos
          a sistemas de información para validar y confirmar su identidad. El
          Cliente no podrá realizar operaciones hasta que proporcione a HUBITER
          la totalidad de la información y documentación de identificación
          requerida y ésta sea validada por este último.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Para la realización de Operaciones en dicha Plataforma, HUBITER podrá
          solicitar al Cliente factores de autenticación, mismos que serán
          determinados conforme a lo siguiente: factor de autenticación se
          utiliza en el inicio de sesión en la plataforma donde el Cliente
          escribe su nombre de usuario y contraseña; y el segundo factor de
          autenticación se usa para confirmar el alta de usuario o cambio de
          contraseña que se envía a su correo electrónico.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Sin perjuicio de lo anterior, para poder realizar operaciones y
          brindar los Servicios y celebrar el Contrato respectivo, el Cliente
          deberá proporcionar toda la documentación e información requerida por
          HUBITER. El Cliente acepta y reconoce que no podrá hacer uso de los
          Servicios en caso de que no proporcione los documentos e información
          requerida. HUBITER podrá solicitar a los Clientes información y
          documentación donde se haga constar los datos de identificación,
          domicilio, ocupación, giro mercantil, nacionalidad, clave única de
          registro de población, Firma Autógrafa Digitalizada, Número
          telefónico, correo electrónico, entre otras que sean requeridas de
          conformidad con la Ley Federal para la Prevención e Identificación de
          Operaciones con Recursos de Procedencia Ilícita, las Reglas de
          Carácter General a que se refiere Ley Federal para la Prevención e
          Identificación de Operaciones con Recursos de Procedencia Ilícita y el
          Reglamento de la Ley Federal para la Prevención e Identificación de
          Operaciones con Recursos de Procedencia Ilícita.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El Cliente se obliga a proporcionar información verídica por lo que
          proporcionará Datos Personales a HUBITER a los cuales se les dará el
          tratamiento y la protección establecidos en el Aviso de Privacidad que
          se encuentra expuesto en la Plataforma y que el Cliente acepta al
          ingresar a la Plataforma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER podrá suspender temporal o definitivamente al Cliente cuyos
          datos no hayan podido ser confirmados o que los mismos hayan resultado
          falsos. En dicho supuesto, el Cliente no podrá hacer uso de la
          Plataforma y se le podrá dar de baja de los posibles Servicios que
          haya solicitado. Asimismo, si el Cliente no está de acuerdo con los
          presentes Términos y Condiciones, Aviso de Privacidad y/o Contrato,
          deberá abstenerse de usar la Plataforma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          La Plataforma deberá ser utilizada de manera personal, privada y
          particular por el Cliente, por lo que la Cuenta no podrá de manera
          alguna ser copiada, modificada, publicada, reproducida o utilizada con
          fines comerciales. Recuperación de contraseña
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El Cliente acepta que será responsable en todo momento de resguardar
          las contraseñas de acceso a la Plataforma y será responsable por
          cualquier robo o extravío de las mismas.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER siguiendo las mejores prácticas de seguridad e identidad no
          guarda ni conoce las contraseñas de los Clientes por lo que ninguno de
          sus empleados, funcionarios, accionistas o directores tiene acceso a
          dicha información.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Para recuperar el acceso a su cuenta el Cliente deberá ingresar al
          apartado dentro de la Plataforma “recuperar contraseña” y escribir el
          correo electrónico con el cual realizó su registro. HUBITER realizará
          la búsqueda del correo electrónico dentro de su base de datos y si
          este coincide con la información del Cliente se le enviará por correo
          electrónico con una contraseña temporal la cual podrá modificar
          posteriormente una vez autenticado en la plataforma.
        </p>
        <h4 style="text-align: justify; font-size: 8pt;">
          Procedimientos y mecanismos para que los clientes reporten el robo o
          extravío de su información
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          En caso de robo o extravío de alguno de los factores de autenticación
          se protegerá la cuenta del Cliente mediante bloqueo por sistema, el
          cual impedirá el acceso y la realización de operaciones en la
          plataforma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El cliente podrá contactar a soporte al cliente mediante alguno de los
          medios autorizados para soporte al cliente o vía correo electrónico.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Cuando se reporte una incidencia de este tipo, para efecto de dar
          seguimiento, se registrará el reporte en la plataforma de atención a
          clientes. Cuando esto suceda se genera un folio único, mismo que será
          compartido con el cliente según el medio en el que haya solicitado
          reporte.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Luego de haber recibido el reporte, HUBITER tomará las medidas
          bloqueando la cuenta, descrito más abajo, para evitar la realización
          de operaciones en la cuenta, hasta el desbloqueo de la misma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Con el propósito de dar seguimiento y mantener informado a los
          clientes que hayan realizado algún reporte, se registra un ticket en
          la plataforma de soporte al cliente, misma que enviará un correo
          electrónico al cliente, con el número de folio asignado. Los clientes
          podrán dar seguimiento a su caso respondiendo al correo electrónico.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Respecto a las responsabilidades derivadas de las operaciones
          efectuadas por los clientes previas a un reporte de robo o extravío de
          su información de identificación o autenticación, HUBITER no se hace
          responsable de las operaciones realizadas previa a la generación del
          reporte o mal uso de la cuenta previo al reporte de la incidencia.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Por su cuenta, el cliente tiene como responsabilidad reportar el
          incidente inmediatamente a HUBITER a fin de bloquear la cuenta e
          impedir mal uso de la misma. Luego del reporte, HUBITER asume la
          responsabilidad por el mal uso de la cuenta. Responsabilidades del
          cliente y de HUBITER respecto al uso de la plataforma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El Cliente acepta que será responsable de las operaciones que realice
          en la Plataforma, del resguardo de la información de autenticación
          necesaria para tener acceso a su cuenta, así como de reportar
          inmediatamente a HUBITER cualquier incidente respecto de su cuenta.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER no se hará responsable de las operaciones que el Cliente
          realice dentro de la Plataforma previas a la existencia de un reporte
          por parte del Cliente o al mal uso que le pueda dar este último a su
          cuenta.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER no se obliga a mantener disponibles todos los servicios
          establecidos en la plataforma.
        </p>
        <h4 style="text-align: justify; font-size: 8pt;">
          Aclaraciones relacionadas con operaciones y servicios
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          Mecanismos y procedimientos que HUBITER pondrá a disposición de los
          clientes para atender las aclaraciones relacionadas con operaciones y
          servicios.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER ha designado al área de Mesa de Ayuda como la unidad
          especializada para atender las consultas, reclamaciones y aclaraciones
          de los Clientes.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El procedimiento de aclaraciones se realizará conforme a lo siguiente:
        </p>
        <div style="font-size: 8pt;">
          <ol type="1">
            <li>
              El cliente enviará un correo electrónico a la siguiente dirección:
              contacto@hubiter.biz, donde deberá señalar lo siguiente:
            </li>
            <ol type="a">
              <li>Cuenta de correo electrónico personal.</li>
              <li>Número de teléfono.</li>
              <li>
                Breve descripción del hecho, objeto de la aclaración.
              </li>
              <li>d. Agregar evidencia que soporte la aclaración.</li>
            </ol>
            <li>
              Una vez recibido dicha aclaración, Un Representante de la Mesa de
              Ayuda de HUBITER contactará al cliente a partir del día laboral
              siguiente a la recepción del correo electrónico, con la finalidad
              de corroborar y confirmar la información enviada por el cliente.
            </li>
            <li>
              La Mesa de Ayuda analizará la solicitud de aclaratoria en un plazo
              de hasta 15 días laborales contados a partir del contacto
              realizado con el cliente.
            </li>
            <li>
              La Mesa de Ayuda ofrecerá una respuesta al cliente el día
              siguiente al plazo de análisis de la solicitud del cliente.
            </li>
          </ol>
        </div>
        <p style="text-align: justify; font-size: 8pt;">
          Lo antes dispuesto es sin perjuicio del derecho del Cliente de acudir
          ante la Comisión Nacional para la Protección y Defensa de los Usuarios
          de Servicios Financieros o ante la autoridad jurisdiccional
          correspondiente.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Comisiones aplicables a los servicios
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER no realizará cobro alguno por el registro de Clientes en la
          Plataforma. Las comisiones aplicables a los Servicios estarán
          disponibles para los Clientes dentro de la Plataforma y en el Contrato
          correspondiente.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Derechos de autor y propiedad intelectual
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER, sus logotipos y todo el material que aparece en la Plataforma
          son marcas, nombres de dominio y nombres comerciales propiedad de
          HUBITER protegidos por tratados internacionales, leyes federales y
          locales en materia de propiedad intelectual y derechos de autor. Los
          derechos de autor sobre el contenido, organización, recopilación,
          información, logotipos, fotografías, imágenes, videos, programas,
          aplicaciones, o en general cualquier información contenida o publicada
          en la Plataforma se encuentra debidamente protegida de conformidad con
          la legislación aplicable en materia de propiedad intelectual.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Se prohíbe expresamente al Cliente modificar, alterar o suprimir, ya
          sea en forma total o parcial, los avisos, marcas, nombres comerciales,
          señas, anuncios, logotipos o en general cualquier indicación que se
          refiere a la propiedad de información contenida en la Plataforma. El
          Cliente se obliga a no modificar, reproducir, copiar, rediseñar,
          aplicar ingeniería inversa, descompilar, adaptar, traducir, preparar
          trabajos derivados de la Plataforma o utilizarla para desarrollar
          cualquier software u otros materiales basados en los mismos.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Confidencialidad
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          Queda estrictamente prohibido al Cliente divulgar cualquier
          información que tenga el carácter de confidencial. Se entiende por
          información confidencial toda la información contenida dentro de la
          Plataforma, como lo es: la información referente a los Activos
          Virtuales, Contratos, actores de las compraventas, estatus de pago,
          así como cualquier otra información a la que el Cliente tenga acceso
          dentro de la Plataforma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          En ningún caso el Cliente podrá utilizar la información confidencial a
          la que tendrá acceso a través de la Plataforma para su beneficio
          personal, ni para beneficio de terceras personas. El Cliente en ningún
          caso deberá de utilizar la información confidencial, si esta implica
          un conflicto de intereses. En caso de que exista un incumplimiento del
          Cliente, HUBITER, sus filiales y cualquier persona afectada podrá
          reclamar al Cliente por la vía civil o penal el cumplimiento de esta
          cláusula más el pago de daños y perjuicios.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">Publicidad</h4>
        <p style="text-align: justify; font-size: 8pt;">
          El Cliente reconoce y acepta que HUBITER es una organización
          independiente de terceros patrocinadores y anunciantes cuya
          información, imágenes, anuncios y demás material publicitario o
          promocional puede ser publicado en la Plataforma. El Cliente reconoce
          y acepta que el material publicitario no forma parte del contenido
          principal que se publica en el Plataforma. Asimismo, reconoce y acepta
          con este acto que este material se encuentra protegido por las leyes
          que en materia de propiedad intelectual e industrial resulten
          aplicables.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Modificaciones a la plataforma
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          Durante la vigencia del presente Contrato, HUBITER podrá modificar la
          Plataforma y su contenido, en cuyo caso le informará al Cliente de
          dichas modificaciones, a través de correo electrónico y la Plataforma.
          Quedan exceptuadas de lo dispuesto a lo establecido anteriormente las
          modificaciones que se realicen para otorgar beneficios a los clientes
          o para adecuarlos a nuevas disposiciones normativas. En estos casos,
          únicamente HUBITER dará aviso a los Clientes, a través de la
          Plataforma.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          En el supuesto de que el Cliente no esté de acuerdo con las
          modificaciones a la Plataforma, podrá solicitar a HUBITER la reversión
          de las modificaciones dentro de un plazo de 30 (treinta) días
          naturales contados a partir de la fecha del aviso de modificación, sin
          que por tal motivo exista responsabilidad alguna a cargo del Cliente.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Materiales de terceros
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          Es posible que el Cliente tenga acceso en la Plataforma a determinados
          servicios o materiales de terceros, o se proporcionen enlaces a sitios
          web de terceros. Al utilizar estos servicios se acepta que HUBITER no
          asume ni se responsabiliza de ninguno de los servicios o productos del
          tercero, ni de examinar o evaluar el contenido, la exactitud, la
          integridad, la vigencia, la validez, el cumplimiento de los derechos
          de autor, la legalidad, la calidad o cualquier otro aspecto de dichos
          sitios web o materiales de terceros.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Modificaciones a los términos y condiciones
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          Durante la vigencia del presente Contrato, HUBITER, podrá modificar
          los presentes términos y condiciones, en cuyo caso le informará al
          Cliente de dichas modificaciones, a través de correo electrónico y/o
          la Plataforma. Para tal efecto HUBITER informará en la Plataforma un
          anuncio que incluirá:
        </p>
        <div style="font-size: 8pt;">
          <ol type="I">
            <li>Nombre del producto o servicio;</li>
            <li>Resumen de todas las modificaciones realizadas;</li>
            <li>Fecha a partir de la cual entran en vigor, y</li>
            <li>
              Derecho del Cliente para dar por terminado los presentes Términos
              y Condiciones.
            </li>
          </ol>
        </div>
        <p style="text-align: justify; font-size: 8pt;">
          Quedan exceptuadas de lo dispuesto a lo establecido anteriormente las
          modificaciones que se realicen para otorgar beneficios a los clientes
          o para adecuarlos a nuevas disposiciones normativas. En estos casos,
          únicamente HUBITER dará aviso a los clientes, a través de la
          Plataforma, indicando que para conocer los cambios deberá acceder a la
          Página de Internet de HUBITER.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          En el supuesto de que el Cliente no esté de acuerdo con las
          modificaciones a los Términos y Condiciones, podrá solicitar a HUBITER
          la terminación del mismo, sin que por tal motivo exista
          responsabilidad alguna a cargo del Cliente, quien únicamente tendrá la
          obligación de pagar los adeudos que se hubieren generado hasta la
          fecha efectiva de terminación del servicio por el concepto de
          comisiones o contraprestaciones.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Renuncia de garantía
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          HUBITER no garantiza que la Plataforma funcionará libre de errores o
          que la Plataforma y/o su servidor están libres de virus informáticos u
          otros mecanismos lesivos. Si por el uso de la Plataforma tiene que
          acudir al servicio técnico o reponer el equipo o datos, HUBITER no
          será responsable de estos gastos. HUBITER recomienda a los Clientes
          mantener copias de seguridad de su contenido. En la medida en que lo
          permita la ley, en ningún caso HUBITER será responsable por el
          borrado, pérdida o modificación no autorizadas de cualquier contenido
          de los Clientes.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Disposiciones Adicionales
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          Por el hecho de usar las aplicaciones o de solicitar o recibir los
          Servicios de HUBITER, cada uno de los Clientes declara que (i) está
          facultado para aceptar y cumplir los Términos y Condiciones, (ii) el
          cumplimiento de los Términos y Condiciones no contraviene ninguna
          disposición que le sea aplicable, ni contrato o convenio alguno del
          que sea parte, (iii) ha obtenido y está en vigor cualquier
          autorización necesaria para cumplir con los Términos y Condiciones,
          (iv) no es parte de ningún litigio o controversia que pudiere tener
          efectos adversos respecto de sus obligaciones conforme a los Términos
          y Condiciones, (v) tanto cualquier acuerdo de voluntades como los
          Términos y Condiciones constituyen obligaciones válidas y exigibles en
          su contra, conforme a sus términos, (vi) se encuentra legalmente
          posibilitado para otorgar su consentimiento vía electrónica respecto
          de los presentes Términos y Condiciones: y (vii) no existe una ley o
          regulación que restrinja o prohíba el uso de nuestros Servicios en la
          jurisdicción, País o Estado donde el Cliente vive .
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          De igual forma el Cliente se obliga a indemnizar a HUBITER, así como a
          sus respectivos funcionarios, directores, colaboradores y empleados (y
          sacarlos en paz y a salvo de los daños, costos, gastos o pérdidas,
          incluyendo honorarios razonables de abogados) por conflictos que
          surjan o se relacionen con el incumplimiento por parte del Cliente a
          cualquiera de sus obligaciones derivadas de los Términos y Condiciones
          o la legislación aplicable.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Cada Cliente que acceda a la Plataforma o que utilice los Servicios de
          HUBITER, se obliga a indemnizar y sacar en paz y a salvo a HUBITER
          respecto de cualquier daño o perjuicio que afecte a HUBITER, como
          consecuencia de cualquier hecho, acto u omisión del Cliente
          relacionado con el acceso a la Plataforma, los Servicios y conviene
          que para resarcirse de dichos daños o perjuicios, HUBITER podrá
          utilizar los recursos que se encuentren en cualquier Cuenta del
          Cliente o ejercer sus derechos en contra del Cliente conforme a la
          legislación aplicable, hasta en tanto el daño y/o perjuicio sea
          resarcido.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Si alguna disposición de los Términos y Condiciones es inválida o no
          exigible conforme a la ley aplicable, las disposiciones restantes
          continuarán en pleno vigor y efecto.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Los presentes Términos y Condiciones y los derechos y obligaciones
          aquí previstos, no podrán ser transferidos o cedidos por los Clientes
          bajo ninguna forma, pero nos reservamos el derecho, de ceder el
          presente, transferir o asignar la información que hemos recopilado de
          ti con motivo de una cesión a subsidiarias, afiliadas, controladoras o
          entidades del mismo grupo empresarial o como consecuencia de cualquier
          otra operación.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          Para la interpretación, cumplimiento y ejecución de los Términos y
          Condiciones, las partes convienen que serán aplicables las leyes
          federales de los Estados Unidos Mexicanos y se someten a la
          jurisdicción y competencia de los tribunales de la Ciudad de México,
          renunciando en este acto a aquellos que por ministerio de ley, fuero o
          residencia le pudieran corresponder, por razón de su domicilio o por
          cualquier otra causa. El Cliente acepta que los Servicios se
          consideran realizados en el territorio de los Estados Unidos Mexicanos
          y más específicamente, en la Ciudad de México.
        </p>
        <p style="text-align: justify; font-size: 8pt;">
          El Cliente manifiesta que ha leído las disposiciones de los Términos y
          Condiciones, y que entiende el alcance de sus obligaciones y derechos
          derivados de los mismos, asumiendo así las obligaciones que derivan de
          su consentimiento, no obrando error de derecho o de hecho, violencia,
          dolo, mala fe, engaño, lesión, incapacidad o cualquier otro vicio que
          pudiera afectar el consentimiento.
        </p>
        <h4 style="text-align: left; font-size: 8pt;">
          Ley aplicable y jurisdicción
        </h4>
        <p style="text-align: justify; font-size: 8pt;">
          Los presentes Términos y Condiciones se regirán e interpretarán de
          conformidad con la legislación aplicable en materia de Prevención e
          Identificación de Operaciones con Recursos de Procedencia Ilícita y en
          lo no previsto se aplicarán, conforme a su naturaleza y de forma
          supletoria, las respectivas leyes especiales, las leyes mercantiles,
          los usos y prácticas bancarias, bursátiles y mercantiles, la
          legislación civil federal, la legislación penal federal, así como el
          Código Fiscal de la Federación respecto de la actualización de multas.
          Cualquier procedimiento de carácter legal que se derive o se relacione
          con este Contrato será dirimido en los tribunales competentes de San
          Pedro Garza García, Nuevo León. El Cliente expresamente renuncia a
          cualquier otra jurisdicción que pudiera corresponderles debido a su
          domicilio presente o futuro o por cualquier otra razón.
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          I accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    };
  }
};
</script>
